import React, { useState, useEffect } from "react"
import {
  formatEventDate,
  formatEventRegDate,
  getCookie,
  getVideoThumbnail,
} from "../../../service/helper"

import VideoModal from "../videoModal"
import { Link } from "gatsby"

const MyEventCard = ({ data, future }) => {
  const [videoURL, setVideoURL] = useState("")
  const [showModal, setShowModal] = useState(false)

  const defaultImage = "/assets/images/placeholder-348x233.png"

  const openVideoModal = url => {
    setVideoURL(url)
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const playVideo = video => {
    openVideoModal(video)
  }

  const getVideoImage = videoURL => {
    if (videoURL && getVideoThumbnail(videoURL)) {
      return getVideoThumbnail(videoURL)
    }
    return null
  }

  const downloadResource = url => {
    let a = document.createElement("a")
    a.target = "_blank"
    document.body.appendChild(a)
    a.style = "display: none"
    a.href = url
    a.download = data.title
    a.click()
    if (typeof window !== "undefined") {
      window.URL.revokeObjectURL(url)
    }
  }
  useEffect(() => {
    const videoImage = document.getElementById(`videoImage-${data.title}`)
    if (videoImage && videoImage.style) {
      videoImage.style.height = "233px"
      videoImage.style.width = "348px"
    }
  }, [data])

  return (
    <>
      <div className="col-lg-4 col-md-6">
        <div className="card_wrap">
          <div className="card event_card event-inner">
            <div className="img_video_wrapper">
              {/* <img className="lazyload"    src="/assets/images/events/upcoming_evt_01.jpg"   alt="" />   */}
              {!data.video ? (
                <img
                  className="lazyload"
                  id={`cardImage-${data.title}`}
                  src={data?.image || defaultImage}
                  alt={data?.title || ""}
                />
              ) : (
                <img
                  className="lazyload"
                  id={`videoImage-${data.title}`}
                  src={getVideoImage(data.video) || defaultImage}
                  alt={data?.title || ""}
                />
              )}
              {data?.video !== null && (
                <button
                  className="video-popup"
                  data-toggle="modal"
                  data-target="#modal_video"
                  data-src="yXG2BeAYVGs"
                  onClick={() => playVideo(data.video)}
                >
                  <img
                    src="/assets/images/icons/video_icon_white.svg"
                    alt="white-play-icon"
                  />
                </button>
              )}
            </div>
            <div className="card-body">
              <div className="evt_category">
                CATEGORY: <span>{data?.category || ""}</span>
                <span className="share_icon">
                  <img src="/assets/images/icons/icon_event_share.svg" alt="" />
                </span>
              </div>
              <Link to={data.path ? `${data.path}` : "/"}>
                <h3 className="evt_title">{data?.title || ""}</h3>
              </Link>
              <p className="evt_small_desc">{data?.overview || ""}</p>
              <div className="link_wrap">
                <a
                  href="javascript:void(0)"
                  onClick={() => downloadResource(data.brochure)}
                >
                  Download Brochure
                </a>
              </div>
              <div className="evt_time">
                <img
                  src="/assets/images/icons/icon_event_calendar.svg"
                  alt=""
                  className="icon"
                />
                {data.eventDate && formatEventDate(data.eventDate)}
                {data?.eventEndDate && ' - ' + formatEventDate(data.eventEndDate)}
                <br />
                {data.registrationDate && (
                  <>
                    Registration Close On:{" "}
                    {formatEventRegDate(data.registrationDate)}
                  </>
                )}
              </div>
              <div className="evt_time">
                <img
                  src="/assets/images/icons/address.svg"
                  alt=""
                  className="icon"
                />
                P.D. Hinduja Hospital and Medical Research Centre, Khar Facility (Formerly Hinduja Healthcare Surgical) 724, 11th Road, Khar West, Mumbai – 400052, India
              </div>
            </div>
            <div className="card-link">
              <Link
                to={data.path ? `${data.path}` : "/"}
                className="btn btn-primary-solid"
              >
                VIEW DETAILS
              </Link>
              {/* <a href="javascript:void(0)" >VIEW DETAILS</a> */}
            </div>
          </div>
        </div>
      </div>

      <VideoModal
        videoURL={videoURL}
        showModal={showModal}
        closeModal={closeModal}
      />
    </>
  )
}

export default MyEventCard
