import React, { useState, useEffect } from "react"

import { acceptOnlyNumbers, formatAmount } from "../../../service/helper"

const PaymentBilling = (props) => {

    const { setTotal, apiData, formData } = props
    const [charges, setCharges] = useState(null)
    const [appointmentData, setAppointmentData] = useState(null)

    useEffect(() => {
        if(apiData && formData) {
            setAppointmentData(formData)
            let chargeSheet = {}
            let xtracharge = [{}]
            let totalCharge = 0
            if(Object?.keys(apiData).length) {
                for (const [key, value] of Object.entries(apiData)) {
                    if(key && typeof key === 'string') if(key === 'proc_charge' || key === 'proc_des' || key == 'reg_service_charge' || key == 'reserv_charge' || key == 'reg_service_des') chargeSheet[`${key}`] = value
                    if(key.includes('add_serv')) {
                        let val = Number(acceptOnlyNumbers(key))
                        if(xtracharge?.length === val-1) xtracharge.push({})
                        xtracharge[val-1][key.split(`_${val}`)[0]] = value
                    }
                    if(key.includes('_charge')) {
                        totalCharge = totalCharge + Number(value)
                    }
                }
            }
            setCharges({ ...apiData, add_serv: xtracharge, totalCharge: totalCharge })
            setTotal(totalCharge)
        } else {
            return null
        }
    },[props])
    return (
        <>
            <div class="form_block appointmentDetailsWrap"> 
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="titleText">Your appointment details:</h3>
                    </div>  
                    { appointmentData?.doctorName &&
                    <div class="col-md-12">
                        <div class="detailsBox">
                            <span class="leftText">Consultant</span><span class="rightText">:  {appointmentData?.doctorName}</span>
                        </div>
                    </div>
                    }
                    {   appointmentData?.appointmentDate &&
                    <div class="col-md-12">
                        <div class="detailsBox">
                            <span class="leftText">Date & Time</span><span class="rightText">:  {appointmentData?.appointmentDate} at {appointmentData?.appointmentTime}</span>
                        </div>
                    </div>
                    }
                    {   charges?.reserv_charge &&
                        <div class="col-md-12">
                            <div class="detailsBox">
                                <span class="leftText">Reservation Amount</span><span class="rightText">:  ₹{formatAmount(charges?.reserv_charge)}/-</span>
                            </div>
                        </div>
                    }
                    { charges?.proc_des && charges?.proc_charge &&
                    <div class="col-md-12">
                        <div class="detailsBox">
                            <span class="leftText">{charges?.proc_des}</span><span class="rightText">:  ₹{formatAmount(charges?.proc_charge)}/-</span>
                        </div>
                    </div>}
                    {charges?.reg_service_des && charges?.reg_service_charge &&
                    <div class="col-md-12">
                        <div class="detailsBox">
                            <span class="leftText">{charges?.reg_service_des}</span><span class="rightText">:  ₹{formatAmount(charges?.reg_service_charge)}/-</span>
                        </div>
                    </div>}
                    {charges?.add_serv_charge_1 && charges?.add_serv_des_1 && charges?.add_serv
                    ?  <>
                        {charges?.add_serv.map((serv, i) => {
                            return <div key={i} class="col-md-12">
                                <div class="detailsBox">
                                    <span class="leftText">{serv?.add_serv_des}</span>1111
                                    <span class="rightText">:  ₹{formatAmount(serv?.add_serv_charge)}/-</span>
                                </div>
                            </div>
                        })}
                    </>
                    : null}
                    <div class="col-md-12">
                        <p class="totalTitle">Total Payble Charges ₹{formatAmount(charges?.totalCharge)}/- </p>
                    </div>
                </div> 
            </div>  
        </>
    )
}

export default PaymentBilling