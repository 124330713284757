import React, { useState, useEffect, useRef } from "react"
import { navigate } from 'gatsby-link'
import { Link } from "gatsby"
import toast from "react-hot-toast"
import SimpleReactValidator from "simple-react-validator"

import Layout from "../layout/layout"
import Breadcrumbs from "../addon/breadcrumbs"
import Meta from "../addon/meta"
import { useLocation } from '@reach/router'

import { handleLogin, isLoggedIn, handleHHLogin } from '../../service/auth'
import useScript from '../../service/useScript'
import API from "../../api/user"
import { acceptOnlyNumbers, concealMobileNo } from "../../service/helper"
import APIButton from "../addon/button/apiButton"
import LinkButton from "../addon/button/linkButton"
import UseCustomValidator from "../../service/simpleValidator"


const Login = () => {

    const location = useLocation()
    const loginValues = { mobileNo : "", otp : "" }
    const { customMobileValidator } = UseCustomValidator()
    const [values, setValues] = useState(loginValues)
    const { mobileNo, otp } = values
    const [verifyOpen, setVerifyOpen] = useState(null);
    const [formType, setFormType] = useState('mobile')
    const [hhNumber, setHHNumber] = useState('')
    const [appointmentData, setAppointmentData] = useState(null)
    const [otpLoading, setOTPLoading] = useState(false)
    const [loading, setLoading] = useState(false) 
    const [loginPage, setLoginPage] = useState(false)
    const [loc, setLoc] = useState(null) 
    const [step2, setStep2] = useState(null)

    const mobileValidator = customMobileValidator
    const [otpValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className = "field_error" >The OTP field is required.</p>}
    )))
    const [hhValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className = "field_error" > {message} </p>}
    )))

    const [, forceUpdateForRegister] = useState();
    
    useScript("/assets/js/custom_select.js");
    useScript("/assets/js/login-register.js");
    const breadcrumbsLogin = [
        { label: 'Home', link: '/' },
        { label: 'Login' }
    ]
    const breadcrumbsVerifyDetails = [
        { label: 'Home', link: '/' },
        { label: 'Verify Details' }
    ]
    const [breadcrumbs, setBreadcrumbs] = useState([])

    const loginUser = async (details) => {
        setLoading(true)
        try {
            const result = await handleLogin(details, loginPage ? true : false)
            if(result?.apiResponse === true && appointmentData) {
                // toast.success("You've logged in")
                navigate(`/confirm-pay`, { state: { formData: appointmentData, loginData: { ...result?.data, pin: result?.data?.hh_number, flow: 'mobile', mobileno: mobileNo } } })
            } else if (result?.apiResponse === true && isLoggedIn()) {
                toast.success("You've logged in")
                navigate(loc.get("redirect_url"));
            }
            if(result?.apiResponse === false) {
                toast.error(result?.data ? result?.data : "Something went wrong")
            }
            setLoading(false)
        } catch (error) {
            let respError = error?.response?.data
            toast.error(respError ? respError : "Something went wrong")
            setLoading(false)
        }
    }

    const onVerify = () => {
        const mobileResult = mobileValidator.current.allValid()
        if (mobileResult === false) {
            mobileValidator.current.showMessages()
            forceUpdateForRegister(1)
            setVerifyOpen(false);
        } else {
            setOTPLoading(true)
            API.sendOTP({
                mobileNo : mobileNo,
                "otpFor": "login"
            }).then(response => {
                setVerifyOpen(true);
                toast.success("OTP sucessfully sent on mobile no.")
                setOTPLoading(false)
            }).catch(err => {
                console.log(err)
                toast.error("Something went wrong. Please try again later")
                setVerifyOpen(false);
                setOTPLoading(false)
            })
        }
    }
    
    const onResendOTP = (newLoginMobile) => {
        const mobileResult = mobileValidator.current.allValid()
        if (mobileResult === false) {
            mobileValidator.current.showMessages()
            forceUpdateForRegister(2)
        } else {
            setOTPLoading(true)
            API.sendOTP({
                mobileNo : newLoginMobile ? newLoginMobile : mobileNo,
                "otpFor": "login"
            }).then(response => {
                setVerifyOpen(true);
                toast.success("OTP sucessfully resend on mobile no.")
                setOTPLoading(false)
            }).catch(err => {
                console.log(err)
                toast.error("Something went wrong. Please try again later")
                setVerifyOpen(false);
                setOTPLoading(false)
                setStep2(false)
            })
        }
    }

    const handleChange = (e) => {
        const { name, value } = e?.target
        let val = acceptOnlyNumbers(value)
        setValues({
            ...values,
            [name]: val
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const hhResult = hhValidator.current.allValid()
        const mobileResult = mobileValidator.current.allValid()
        const otpResult = otpValidator.current.allValid()
        !verifyOpen && !otp && onVerify()
        if(!mobileResult) {
            mobileValidator.current.showMessages()
            forceUpdateForRegister(3)
            setVerifyOpen(false);
        } 
        if(!otpResult) {
            otpValidator.current.showMessages()
            forceUpdateForRegister(4)
        }
        if(loginPage && hhResult) {
            hhValidator.current.showMessages()
            forceUpdateForRegister(5)
        }
        if(mobileResult && otpResult && mobileNo && otp) {
            setLoading(true)
            loginUser({...values, hhNo: hhNumber});
        }
    }

    const handleHHSubmit = (e) => {
        e.preventDefault();
        const hhResult = hhValidator.current.allValid()
        if(!hhResult) {
            hhValidator.current.showMessages()
            forceUpdateForRegister(6)
        }
        if(hhResult && hhNumber?.length) {
            validateUserHHNumber()
        }
    }

    const validateUserHHNumber = () => {
        setLoading(true)
        API.validateHHNumber(hhNumber)
        .then(res => {
            if(res) {
                getDetailsHHNumber()
            } else {
                toast.error("Invalid HH Number")
                setLoading(false)
            }
        })
        .catch(err => {
            console.log(err)
            toast.error("Invalid HH Number")
        })
    }

    const getDetailsHHNumber = async () => {
        try {
            const result = await handleHHLogin(hhNumber)
            if(result && appointmentData) {
                navigate(`/confirm-pay`, { state: { formData: appointmentData, loginData: result } })
            } else if (loginPage && result?.mobileno) {
                setStep2(true)
                setVerifyOpen(true)
                setValues({
                    ...values,
                    ['mobileNo']: result?.mobileno
                })
            } else if (loginPage && !result?.mobileno?.length) {
                toast.error("No mobile number associated to HH number")
            } else if (result) {
                navigate(loc.get("redirect_url"));
            }
            setLoading(false)
        } catch (error) {
            toast.error("Something went wrong")
            console.log(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        if(loginPage && mobileNo?.length) {
            onResendOTP(mobileNo)
        }
    },[mobileNo])

    useEffect(() => {
        if(location && window && location?.state) {
            if(location.state?.appointmentData) {
                setAppointmentData(location.state?.appointmentData)
                setLoginPage(false)
                setBreadcrumbs(breadcrumbsVerifyDetails)
                forceUpdateForRegister(7)
            } else {
                setLoginPage(true)
                setBreadcrumbs(breadcrumbsLogin)
                forceUpdateForRegister(8)
            }
        }
    },[location])

    useEffect(() => {
        const loc = new URLSearchParams(document.location.search.substring(1))
        setLoc(loc)
    }, [])
    const metaTags = [];
    return (
        <Layout>
            <Meta
                title="login"
                files={{
                    js: [],
                    css: ["/assets/css/login.css" ,"/assets/css/accessibility.css"]
                }}
                tags={metaTags}
            />
            <main className="innerpage">
                <Breadcrumbs
                    breadcrumbs={loginPage ? breadcrumbsLogin : breadcrumbsVerifyDetails}
                />
            </main>
            <section className="section_bg login_section pt-first-section">
                <div className="container">
                    <h2 className="section-heading">{loginPage ? 'Login' : 'Verify Details'}</h2>
                    <div className="row no-gutters">
                        <div className="col-lg-12 col-md-12">
                            <div className="white_rounded_box">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                        {/* <a className="nav-link active" data-toggle="tab" href="#tab-login" role="tab">Login</a> */}
                                        <a className="nav-link active">{loginPage ? 'Login' : 'Verify Details'}</a>
                                    </li>
                                    {/* <li className="nav-item">
                                        <Link to={`/user/register${(loc && loc.get("redirect_url")) ? `?redirect_url=${loc.get("redirect_url")}` : ``}`} className="nav-link">Register</Link>
                                    </li> */}
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane active" id="tab-login" role="tabpanel">
                                        <div className="row">
                                            <div className="col-lg-8 col-md-12">
                                                <div className="form_wrap">
                                                    <div className="row">
                                                        {location.state?.appointmentData &&
                                                        <>
                                                            <div class="col-md-12">
                                                                <div class="row mb-4">
                                                                    <div class="col-md-4 col-12">
                                                                        <div class="custom-control custom-radio pl-0">
                                                                            <input type="radio" checked={formType === 'mobile' ? true : false} id="mobile" onChange={() => setFormType('mobile')} />
                                                                            <label for="mobile" className="ml-1"> Mobile Number</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4 col-12">
                                                                        <div class="custom-control custom-radio pl-0">
                                                                            <input type="radio" checked={formType === 'hh' ? true : false} id="hh" onChange={() => { setFormType('hh'); setVerifyOpen(false) }} />
                                                                            <label for="hh" className="ml-1">HH Number</label>
                                                                        </div>
                                                                    </div>
                                                                </div>   
                                                            </div> 
                                                        </>}
                                                        {loginPage 
                                                        ? <>
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="row otp_row">
                                                                    <div className="col-lg-8 col-md-12">
                                                                        <div className="form-group -animated">
                                                                            <label>Enter HH Number <span className="mandatory">*</span></label>
                                                                            <input className="form-control" type="text" value={hhNumber} onChange={(e) => setHHNumber(acceptOnlyNumbers(e?.target?.value))} name="hhNumber" />
                                                                            {hhValidator.current.message('HhNumber', hhNumber, 'required|min:3|max:10')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {verifyOpen && 
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="row otp_row">
                                                                    <div className="col-lg-8 col-md-12">
                                                                        <div className="form-group -animated -active select-disabled">
                                                                            <label>Enter Mobile Number <span className="mandatory">*</span></label>
                                                                            <input className="form-control" type="text" value={concealMobileNo(mobileNo)} onChange={handleChange} name="mobileNo" />
                                                                            {mobileValidator.current.message('mobileNumber', mobileNo, 'required|min:10|max:10|validateMobile')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        </>
                                                        : <>
                                                            {formType === 'mobile'
                                                            ? <div className="col-lg-12 col-md-12">
                                                                <div className="row otp_row">
                                                                    <div className="col-lg-8 col-md-12">
                                                                        <div className="form-group -animated">
                                                                            <label>Enter Mobile Number <span className="mandatory">*</span></label>
                                                                            <input className="form-control" type="text" value={mobileNo} onChange={handleChange} name="mobileNo" />
                                                                            {mobileValidator.current.message('mobileNumber', mobileNo, 'required|min:10|max:10|validateMobile')}
                                                                        </div>
                                                                        {/* {!verifyOpen 
                                                                        ? <LinkButton title={`Verify with OTP`} onBtnClick={onVerify} loading={otpLoading} />
                                                                        : null} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : <div className="col-lg-12 col-md-12">
                                                                <div className="row otp_row">
                                                                    <div className="col-lg-8 col-md-12">
                                                                        <div className="form-group -animated">
                                                                            <label>Enter HH Number <span className="mandatory">*</span></label>
                                                                            <input className="form-control" type="text" value={hhNumber} onChange={(e) => setHHNumber(acceptOnlyNumbers(e?.target?.value))} name="hhNumber" />
                                                                            {hhValidator.current.message('HhNumber', hhNumber, 'required|min:3|max:10')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        </>}
                                                        {verifyOpen 
                                                        ? <div className="col-lg-12 col-md-12">
                                                            <div className="row otp_row">
                                                                <div className="col-lg-8 col-md-12">
                                                                    <div className="form-group -animated -focus">
                                                                        <label>Enter OTP <span className="mandatory">*</span></label>
                                                                        <input className="form-control" type="text" value={otp} maxlength="6" onChange={handleChange} name="otp" />
                                                                        {otpValidator.current.message('OTP', otp, 'required')}
                                                                    </div>
                                                                    <LinkButton title={`Resend OTP`} resend onBtnClick={() => onResendOTP()} loading={otpLoading} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}
                                                    </div>
                                                    <div className="btn_wrap">
                                                        {/* <button className="btn btn-primary" disabled={mobileNo && otp || hhNumber ? false : true} onClick={(e) => formType === 'mobile' ? handleSubmit(e) : handleHHSubmit(e)}>Login</button> */}
                                                        {loginPage 
                                                        ? <APIButton title={`Submit`} 
                                                            loading={loading} 
                                                            disabled={mobileNo && otp || hhNumber?.length >= 3 ? false : true} 
                                                            onBtnClick={(e) => step2 ? handleSubmit(e) : handleHHSubmit(e)}  
                                                            className={`btn btn-primary`} 
                                                        />
                                                        : <APIButton title={`Submit`} 
                                                            loading={mobileNo && !verifyOpen && otpLoading || otp && loading} 
                                                            disabled={mobileNo || hhNumber?.length >= 3 ? false : true} 
                                                            onBtnClick={(e) => formType === 'mobile' ? handleSubmit(e) : handleHHSubmit(e)}  
                                                            className={`btn btn-primary`}
                                                        />}
                                                    </div>
                                                    {/* <div className="not_a_member">
                                                        Not a Member? <Link to={`/user/register${(loc && loc.get("redirect_url")) ? `?redirect_url=${loc.get("redirect_url")}` : ``}`}>Click here to Register</Link>
                                                    </div> */}
                                                </div>
                                            </div>
                                            {!location.state?.appointmentData && 
                                            <div className="col-lg-4 col-md-12 blue_col">
                                                <div className="have_hh_no">
                                                    <p>  To update your mobile number and email id in hospital records. 
                                                    </p>
                                                    <Link to="/activate-account" className="btn btn-primary">
                                                    Click Here</Link>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Login